import { fullPublisher, publisher, releasedEvent } from '@/constants/seo'
import { NextSeoPropsWithRequiredFields } from '@/layout/Seo'
import { MovieJsonLdProps } from '@/layout/Seo/JsonLd'
import { TVSeriesJsonLdProps } from '@/layout/Seo/JsonLd/TVSeriesJsonLd'
import { ContentRating, Genre, Person } from '@/layout/Seo/JsonLd/types'
import { MediaMetaDataParts } from '@/layout/Seo/SeoHelper'
import { CatalogMovie, CatalogSeries } from '@/types/generated-content-catalog-types'
import { getCloudinaryImageUrl } from '@/utils/Cloudinary'
import { formatCanonicalUrl } from '@/utils/url'
import {
  filterTitleDirector,
  filterTitleProducer,
  formatTitleRating,
  formatTitleTitleArtImagePath,
  isSeries,
  isSeriesCast,
  formatTitleDuration,
  isSeason,
} from './contentCatalogService'
import { CatalogCrew, CatalogCast, CatalogTitle, CatalogExternalId } from './types'

interface SeoPropsInput {
  path: string
  title: string
  catalogTitle?: CatalogTitle
  locale: string
}
/**
 * Preps input props for next-seo, which allows attributes available in
 * the Open Graph protocol, https://ogp.me/
 */
export function mapSeoProps({ path, title, catalogTitle, locale }: SeoPropsInput): NextSeoPropsWithRequiredFields {
  const description = formatDescription(catalogTitle)
  const imageUrl = formatImageUrl(catalogTitle)
  return {
    canonical: formatCanonicalUrl({ locale, path }),
    description,
    openGraph: {
      description,
      type: `video.${formatOpenGraphType(catalogTitle)}`,
      images: [
        {
          url: imageUrl,
          width: 1200,
          height: 630,
          alt: description,
        },
      ],
      site_name: 'Angel Studios',
      title,
      url: formatCanonicalUrl({ locale, path }),
    },
    title,
    additionalMetaTags: [
      {
        property: 'twitter:image',
        content: imageUrl,
      },
    ],
  }
}

type JsonLdLike = MovieJsonLdProps & TVSeriesJsonLdProps
interface JsonLdInput {
  path: string
  catalogTitle: CatalogMovie | CatalogSeries
  locale: string
}
export function mapJsonLdProps({ path, catalogTitle, locale }: JsonLdInput): JsonLdLike {
  return {
    actor: formatActors(catalogTitle),
    contentRating: formatTitleRating(catalogTitle) as ContentRating | undefined,
    dateCreated: new Date(catalogTitle.originalReleaseDate).toISOString(),
    description: formatDescription(catalogTitle),
    director: filterTitleDirector(catalogTitle).map(formatCrewPerson),
    duration: formatDurationIso8601(catalogTitle),
    genre: formatGenres(catalogTitle),
    image: formatImageUrl(catalogTitle),
    name: catalogTitle?.title,
    producer: filterTitleProducer(catalogTitle).map(formatCrewPerson),
    publisher,
    releasedEvent,
    sameAs: formatSameAsUrls(catalogTitle),
    url: formatCanonicalUrl({ locale, path }),
  }
}

export function formatSameAsUrls(catalogTitle: CatalogTitle): string[] {
  const formatters: { [source: string]: (externalId: CatalogExternalId) => string } = {
    rotten_tomatoes: formatRottenTomatoesUrl.bind(null, catalogTitle),
    imdb: formatImdbUrl,
    wikipedia: formatWikipediaUrl,
  }
  const supportedSources = Object.keys(formatters)
  const externalIds = catalogTitle?.externalIds.filter((id) => supportedSources.includes(id.source))
  return externalIds.map((externalId) => {
    return formatters[externalId.source](externalId)
  })
}

function formatRottenTomatoesUrl(catalogTitle: CatalogTitle, externalId: CatalogExternalId): string {
  return `https://www.rottentomatoes.com/${isSeries(catalogTitle) ? 'tv' : 'm'}/${externalId.id}`
}

function formatImdbUrl(externalId: CatalogExternalId): string {
  return `https://www.imdb.com/title/${externalId.id}`
}

function formatWikipediaUrl(externalId: CatalogExternalId): string {
  return `https://wikipedia.org/wiki/${externalId.id}`
}

function formatOpenGraphType(catalogTitle?: CatalogTitle): MediaMetaDataParts['type'] {
  return catalogTitle ? (isSeries(catalogTitle) ? 'tv_show' : 'movie') : 'other'
}

function formatDescription(catalogTitle?: CatalogTitle): string {
  return catalogTitle?.description.short || (fullPublisher.slogan as string)
}

function formatImageUrl(catalogTitle?: CatalogTitle): string {
  return catalogTitle
    ? getCloudinaryImageUrl({ path: formatTitleTitleArtImagePath(catalogTitle), width: 1200, height: 630 })
    : (fullPublisher.logo as string)
}

export function formatActors(catalogTitle?: CatalogTitle): Person[] {
  return !catalogTitle || isSeason(catalogTitle) ? [] : catalogTitle?.cast.map(formatCastPerson) || []
}

function formatCastPerson(cast: CatalogCast): Person {
  return {
    type: 'Person',
    name: cast.name,
    character: {
      type: 'Person',
      name: isSeriesCast(cast) ? cast.characters[0] : cast.character,
    },
  }
}

function formatCrewPerson(cast: CatalogCrew): Person {
  return {
    type: 'Person',
    name: cast.name,
  }
}

export function formatGenres(catalogTitle?: CatalogTitle): Genre[] {
  return !catalogTitle || isSeason(catalogTitle) ? [] : (catalogTitle?.genres as Genre[])
}

/**
 * @see https://schema.org/Duration
 * @see https://en.wikipedia.org/wiki/ISO_8601
 */
function formatDurationIso8601(catalogTitle?: CatalogTitle): string | undefined {
  if (!catalogTitle) return undefined
  const partialFormat = formatTitleDuration(catalogTitle)

  return partialFormat && 'PT' + partialFormat
}
