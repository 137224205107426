import { cLogger } from '@/utils/logging/client-logger'
import { ProjectLinkMeta } from '../ProjectsService'
import { CatalogTitle } from './types'

export interface CatalogTitleProjectMap {
  [key: string]: ProjectLinkMeta | undefined
}

export function findHydraExternalId(title: CatalogTitle): string {
  const hydraId = title.externalIds.find((id) => id.source === 'hydra')?.id
  if (hydraId) {
    return hydraId
  } else {
    cLogger().error(
      `Content Catalog title "${title.title}" is missing a required external id with a source of "hydra"`,
      { title },
    )
    return title.id
  }
}

export function findTitleProjectSlug(title?: CatalogTitle): string | undefined {
  const slug = title?.externalIds?.find((id) => id.source === 'hydra_project_slug')?.id
  if (slug) return slug

  cLogger().error(
    `Content Catalog title "${title?.title}" is missing a required external id with a source of "hydra_project_slug.`,
    { title },
  )
}
