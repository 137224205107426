import { cLogger } from './logging/client-logger'
import { getBasePath } from './sitemap/base'

interface formatCanonicalParams {
  locale: string
  path: string
}

export function formatCanonicalUrl({ locale, path }: formatCanonicalParams): string {
  const base = getBasePath(locale)

  const isCanonical = /^.+:\/\/.*$/.test(path)
  if (isCanonical) {
    cLogger().info('formatCanonicalUrl: This path is already a fully qualified url.', { base, locale, path })
    return path
  }

  const separator = !path || path.startsWith('/') ? '' : '/'
  return `${base}${separator}${path}`
}
